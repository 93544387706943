import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "reset-password" } },
    [
      _c(
        VToolbar,
        { attrs: { flat: "", color: "dark" } },
        [
          _c(
            VBtn,
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.checkBackFunction()
                },
              },
            },
            [
              _c(VIcon, { attrs: { color: "primary", size: "30" } }, [
                _vm._v(" mdi-arrow-left "),
              ]),
            ],
            1
          ),
          _vm.gs.isMobile()
            ? _c(VToolbarTitle, { staticClass: "steps" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("alpha_register_step_count", {
                        step: _vm.step,
                        totalSteps: 3,
                      })
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          !_vm.gs.isMobile()
            ? _c("img", {
                staticClass: "pl-10",
                attrs: {
                  alt: "DXA",
                  src: require("../../../../assets/dealboard-logo-white.svg"),
                  height: "40px",
                },
              })
            : _vm._e(),
          _c(VSpacer),
          !_vm.gs.isMobile() &&
          !_vm.$route.name.includes("AlphaRegisterClient") &&
          _vm.$route.name != "AlphaUserRegisterClient" &&
          _vm.$route.name != "WelcomePage"
            ? _c(
                VMenu,
                {
                  staticStyle: { "z-index": "1000" },
                  attrs: {
                    bottom: "",
                    left: "",
                    "min-width": "200",
                    "offset-y": "",
                    origin: "top right",
                    transition: "scale-transition",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var attrs = ref.attrs
                          var on = ref.on
                          return [
                            _vm.selectedLanguage != null
                              ? _c(
                                  VCard,
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "btn_lang d-flex mr-5",
                                        staticStyle: { width: "125px" },
                                        attrs: {
                                          height: "30px",
                                          "min-width": "100px",
                                        },
                                      },
                                      "v-card",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(VImg, {
                                      attrs: {
                                        "max-width": "23px",
                                        height: "23px",
                                        src: _vm.selectedLanguageAvatar(),
                                      },
                                    }),
                                    _vm.selectedLanguage.value == "pt"
                                      ? _c("p", { staticClass: "p_br" }, [
                                          _vm._v(" PORTUGUÊS/BR "),
                                        ])
                                      : _c("p", { staticClass: "p_en" }, [
                                          _vm._v("ENGLISH"),
                                        ]),
                                  ],
                                  1
                                )
                              : _c(
                                  VBtn,
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "ml-2",
                                        attrs: { "min-width": "0", text: "" },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c(VIcon, [_vm._v("mdi-account-voice")])],
                                  1
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    797174025
                  ),
                },
                [
                  _c(
                    VList,
                    { attrs: { tile: false, flat: "", nav: "" } },
                    _vm._l(_vm.langs, function (l, i) {
                      return _c(
                        VListItem,
                        {
                          key: i,
                          on: {
                            click: function ($event) {
                              return _vm.changeLanguage(l)
                            },
                          },
                        },
                        [
                          _c(VListItemTitle, {
                            staticStyle: { "line-height": "1.4" },
                            domProps: { textContent: _vm._s(l.text) },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.gs.isMobile()
        ? _c(VProgressLinear, {
            staticClass: "dxa-progress-bar",
            staticStyle: { "margin-bottom": "-10px" },
            attrs: {
              color: "primary",
              height: "4",
              value: (_vm.step * 100) / 3,
            },
          })
        : _vm._e(),
      _c(
        VContainer,
        {
          class: _vm.gs.isMobile() ? "pa-4" : "",
          style: _vm.gs.isMobile() ? "" : "width: fit-content",
        },
        [
          _c(VSlideYTransition, { attrs: { appear: "" } }, [
            _c(
              "div",
              { staticClass: "justify-center" },
              [
                _vm.step == 1
                  ? _c(
                      "h2",
                      {
                        staticClass: "confirm_title mt-2",
                        style: _vm.gs.isMobile() ? "" : "text-align: left",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("confirm_email")) + " ")]
                    )
                  : _c(
                      "h2",
                      {
                        staticClass: "confirm_title mt-2",
                        style: _vm.gs.isMobile() ? "" : "text-align: left",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("pwd_reset")) + " ")]
                    ),
                _vm.step == 1
                  ? _c(
                      "h3",
                      {
                        staticClass: "confirm_content mt-6",
                        style: _vm.gs.isMobile()
                          ? ""
                          : "text-align: left; color: #9C9C9C",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("enter_email")) + " ")]
                    )
                  : !_vm.gs.isMobile()
                  ? _c(
                      "h3",
                      {
                        staticClass: "confirm_content mt-6",
                        staticStyle: { "text-align": "left", color: "#9c9c9c" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("pwd_reset_email")) + " ")]
                    )
                  : _c("h3", { staticClass: "confirm_content mt-6" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("new_password_link", {
                              account: _vm.account,
                            })
                          ) +
                          " "
                      ),
                      _c("strong", [_vm._v(_vm._s(_vm.account))]),
                      _vm._v(". " + _vm._s(_vm.$t("email_access")) + " "),
                    ]),
                _vm.email_sent && _vm.step == 2
                  ? _c("div", { staticClass: "mt-12" }, [
                      !_vm.gs.isMobile()
                        ? _c(
                            "div",
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "steps mb-3",
                                  staticStyle: { color: "var(--dark)" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("alpha_register_step_count", {
                                          step: _vm.step,
                                          totalSteps: 3,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(VProgressLinear, {
                                staticClass: "dxa-progress-bar",
                                staticStyle: { "margin-bottom": "-10px" },
                                attrs: {
                                  color: "primary",
                                  height: "4",
                                  value: (_vm.step * 100) / 3,
                                },
                              }),
                              _c(VCard, { staticClass: "desktop_card" }, [
                                _c("img", {
                                  staticClass: "x_logo",
                                  staticStyle: { opacity: "0" },
                                  attrs: {
                                    src: require("../../../../assets/dxa-x.png"),
                                    alt: "DXA",
                                  },
                                }),
                                _c("img", {
                                  staticClass: "stars",
                                  attrs: {
                                    src: require("../../../../assets/dxa-stars-background.png"),
                                    alt: "DXA",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "card_content" },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass: "confirm_content",
                                        staticStyle: { "text-align": "left" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("new_password_link", {
                                                account: _vm.account,
                                              })
                                            ) +
                                            " "
                                        ),
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.account)),
                                        ]),
                                        _vm._v(". "),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("email_access")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "resend_btn mt-6",
                                        staticStyle: {
                                          "padding-left": "0 !important",
                                        },
                                        attrs: { text: "", color: _vm.color },
                                        on: {
                                          click: function ($event) {
                                            return _vm.reset_password()
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("resend_link")))]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "resend_btn mt-6",
                                  staticStyle: { padding: "0" },
                                  attrs: { text: "", color: _vm.color },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reset_password()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("resend_link")))]
                              ),
                            ],
                            1
                          ),
                    ])
                  : _c(
                      "div",
                      { staticClass: "mt-12" },
                      [
                        !_vm.gs.isMobile()
                          ? _c(
                              "div",
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "steps mb-3",
                                    staticStyle: { color: "var(--dark)" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("alpha_register_step_count", {
                                            step: _vm.step,
                                            totalSteps: 3,
                                          })
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(VProgressLinear, {
                                  staticClass: "dxa-progress-bar",
                                  staticStyle: { "margin-bottom": "-10px" },
                                  attrs: {
                                    color: "primary",
                                    height: "4",
                                    value: (_vm.step * 100) / 3,
                                  },
                                }),
                                _c(VCard, { staticClass: "desktop_card" }, [
                                  _c("img", {
                                    staticClass: "x_logo",
                                    staticStyle: { opacity: "0" },
                                    attrs: {
                                      src: require("../../../../assets/dxa-x.png"),
                                      alt: "DXA",
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "stars",
                                    attrs: {
                                      src: require("../../../../assets/dxa-stars-background.png"),
                                      alt: "Icon",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "card_content" },
                                    [
                                      _c(
                                        "h3",
                                        {
                                          staticClass: "confirm_content",
                                          staticStyle: { "text-align": "left" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("enter_email")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VForm,
                                        {
                                          ref: "form_email",
                                          staticStyle: { width: "50%" },
                                          attrs: {
                                            "lazy-validation": _vm.lazy_email,
                                          },
                                          on: {
                                            submit: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.checkFormEmail()
                                            },
                                          },
                                          model: {
                                            value: _vm.valid_email,
                                            callback: function ($$v) {
                                              _vm.valid_email = $$v
                                            },
                                            expression: "valid_email",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "div_email mt-11" },
                                            [
                                              _c(
                                                "h3",
                                                { staticClass: "email_title" },
                                                [_vm._v("E-mail")]
                                              ),
                                              _c(VTextField, {
                                                staticClass:
                                                  "my-input-style mt-2",
                                                attrs: {
                                                  solo: "",
                                                  flat: "",
                                                  "background-color": "#E9E9E9",
                                                  color: "primary",
                                                  rules: [
                                                    _vm.required,
                                                    _vm.vs.email_valid,
                                                  ],
                                                },
                                                on: {
                                                  keydown: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "space",
                                                        32,
                                                        $event.key,
                                                        [" ", "Spacebar"]
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    $event.preventDefault()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.account,
                                                  callback: function ($$v) {
                                                    _vm.account = $$v
                                                  },
                                                  expression: "account",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                position: "absolute",
                                                bottom: "5vh",
                                              },
                                            },
                                            [
                                              _c(
                                                "pages-btn",
                                                {
                                                  staticClass:
                                                    "send_btn v-btn--text white-color",
                                                  attrs: {
                                                    type: "submit",
                                                    block: "",
                                                    loading: _vm.loading,
                                                    color: "primary",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("confirm_data")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c(
                              VForm,
                              {
                                ref: "form_email",
                                attrs: { "lazy-validation": _vm.lazy_email },
                                on: {
                                  submit: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.checkFormEmail()
                                  },
                                },
                                model: {
                                  value: _vm.valid_email,
                                  callback: function ($$v) {
                                    _vm.valid_email = $$v
                                  },
                                  expression: "valid_email",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "div_email" },
                                  [
                                    _c("h3", { staticClass: "email_title" }, [
                                      _vm._v("E-mail"),
                                    ]),
                                    _c(VTextField, {
                                      staticClass: "my-input-style mt-2",
                                      attrs: {
                                        id: "email_field",
                                        solo: "",
                                        flat: "",
                                        "background-color": "#E9E9E9",
                                        color: "primary",
                                        rules: [
                                          _vm.required,
                                          _vm.vs.email_valid,
                                        ],
                                        label: _vm.$t("email"),
                                        placeholder: "nome@email.com",
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                        },
                                      },
                                      model: {
                                        value: _vm.account,
                                        callback: function ($$v) {
                                          _vm.account = $$v
                                        },
                                        expression: "account",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "pages-btn",
                                  {
                                    staticClass: "send_btn v-btn--text",
                                    style:
                                      "margin-left: auto; margin-top: 55vh; color: var(--white)",
                                    attrs: {
                                      type: "submit",
                                      block: "",
                                      loading: _vm.loading,
                                      color: "primary",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("send")))]
                                ),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                _vm.error
                  ? _c(
                      VAlert,
                      { staticClass: "ma-2", attrs: { type: "error" } },
                      [_vm._v(" " + _vm._s(_vm.$t(_vm.error)) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }